import React from "react";
import { Redirect } from "react-router-dom";

// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
// Users
import UsersList from "../pages/Users/UsersList";
import CreateUser from "../pages/Users/CreateUser";
import EditUser from "../pages/Users/EditUser";
import ViewUser from "../pages/Users/ViewUser";

// Sales
import SalesList from "../pages/Sales/SalesList";
import CreateSale from "../pages/Sales/CreateSale";
import EditSale from "../pages/Sales/EditSale";
import ViewSale from "../pages/Sales/ViewSale";

//Logs
import Logs from "../pages/Logs/Logs";

const authProtectedRoutes = [
	{ path: "/dashboard", component: Dashboard },
	{ path: "/profile", component: UserProfile },
	{ path: "/users", component: UsersList },
	{ path: "/add-user", component: CreateUser },
	{ path: "/user/:userId", component: EditUser },
	{ path: "/user-details/:userId", component: ViewUser },
	{ path: "/sales", component: SalesList },
	{ path: "/sale/:saleId", component: EditSale },
	{ path: "/sale-details/:saleId", component: ViewSale },
	{ path: "/add-sale", component: CreateSale },
	{ path: "/logs", component: Logs },
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/register", component: Register },

];

export { authProtectedRoutes, publicRoutes };
