import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle, FormGroup, Button, Alert } from "reactstrap";

import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { getLoggedInUserID, getLoggedInUser, api_url, getLoggedInUserRole, getAuthHeaders } from '../../helpers/fakebackend_helper';

import axios from 'axios';


class CreateSale extends Component {
    constructor() {
        super();
        this.state = {
            current_userID: getLoggedInUserID(),
            token: '',
            success: '',
            error: '',
            loader: false,
            pending_reason: false,
            userRole: getLoggedInUserRole(),
            headers: getAuthHeaders(),
            tech_options: [],
            closed_reason: false,
            closed_reason_yes: false
        };
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.setState({
            current_userID: getLoggedInUserID(),
            token: getLoggedInUser().data.authtoken,
            userRole: getLoggedInUserRole()
        });

        // tech_id_fk
        axios.get(api_url + '/user/techUser/', { headers: this.state.headers })
            .then(res => {
                const tech_users = res.data.data;
                if (tech_users) {
                    tech_users.map((item) => {
                        let newRow = {
                            tech_id_fk: item.uacc_id,
                            name: item.upro_first_name + ' ' + item.upro_last_name
                        };
                        this.setState((state) => ({
                            tech_options: [
                                ...state.tech_options,
                                newRow
                            ],
                        }));
                    });
                }
            });
    }

    statusHandler = (e) => {
        if (e.target.value == 'Pending') {
            this.setState({
                pending_reason: true
            })
        } else {
            this.setState({
                pending_reason: false
            })
        }

        if (e.target.value == 'Closed') {
            this.setState({
                closed_reason: true
            })
        } else {
            this.setState({
                closed_reason: false
            })
        }
    }

    closedHandler = (e) => {
        if (e.target.value == '1') {
            this.setState({
                closed_reason_yes: true
            })
        } else {
            this.setState({
                closed_reason_yes: false
            })
        }
    }

    setSaleDate = (e) => {
        this.setState({
            sale_date: e.target.value
        })
    }

    // handleValidSubmit
    handleValidSubmit(event, values) {

        this.setState({
            loader: true
        });
        values.user_id_fk = getLoggedInUserID();

        axios.post(api_url + '/sale', values, { headers: this.state.headers })
            .then(res => {
                if (res.data.success) {
                    this.setState({ success: res.data.message });
                    this.formRef.current.reset();
                    this.setState({
                        loader: false
                    });
                    setTimeout(() => {
                        this.setState({
                            success: '',
                            error: ''
                        });
                    }, 4000);
                } else {
                    this.setState({ error: res.data.message });
                }
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Sales" breadcrumbItem="Add Sale" />

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        {this.state.userRole != 3 ? <Link to="/sales" id="" className="btn btn-primary waves-effect waves-light float-right mb-2">Manage Sales</Link> : null}

                                        <CardTitle className="mb-4">Personal Information</CardTitle>
                                        <AvForm id="saleForm" ref={this.formRef} onValidSubmit={this.handleValidSubmit}>

                                            {this.state.error && this.state.error ? <Alert className="col-lg-12" color="danger">{this.state.error}</Alert> : null}
                                            {this.state.success && this.state.success ? <Alert className="col-lg-12" color="success">{this.state.success}</Alert> : null}
                                            {this.state.loader && this.state.loader ? <div className="my-spinner"><div className="spinner-border text-primary m-1" role="status"><span className="sr-only">Loading...</span></div></div> : null}

                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField type="select" name="tech_id_fk" label="Select technician" className="capitalize-text" required>
                                                                <option defaultValue>Select technician</option>
                                                                {
                                                                    this.state.tech_options.map((tech_user, index) =>
                                                                        <option key={index} value={tech_user.tech_id_fk}>{tech_user.name}</option>
                                                                    )
                                                                }
                                                            </AvField>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="sale_by" label="Sale By" value="" className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="payment_by" label="Payment By" value="" className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="close_by" label="Close By" value="" className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="first_name" label="First Name" value="" className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="last_name" label="Last Name" value="" className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="email" label="Email" value="" className="form-control" type="email" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="phone" label="Phone" value="" className="form-control" type="text" validate={{ number: true }} required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="alt_phone" label="Alt Phone" value="" className="form-control" type="text" validate={{ number: true }} required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="cx_id" label="CX ID" value="" className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="gateway" label="Gateway" value="" className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="bttr" label="BTTR" value="" className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="6">
                                                            <AvField name="zip_code" label="Zipcode" value="" className="form-control" type="text" required />
                                                        </Col>
                                                        <Col lg="6">
                                                            <AvField name="address" label="Address" value="" className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="6">
                                                            <AvField name="plan" label="Service" value="" className="form-control" type="text" required />
                                                        </Col>
                                                        <Col lg="6">
                                                            <AvField name="amount" label="Amount" value="" className="form-control" type="text" validate={{ number: true }} required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="6">
                                                            <label>Date</label>
                                                            <input className="form-control" type="date" defaultValue="" onChange={this.setSaleDate} id="example-date-input" />
                                                            <AvField name="sale_date" value={this.state.sale_date} type="hidden" />
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup className="mb-2" row>
                                                                <Col lg="12">
                                                                    <AvField type="select" name="kyc_status" label="KYC Status" className="capitalize-text" required>
                                                                        <option defaultValue>Select status</option>
                                                                        <option value="Yes">Yes</option>
                                                                        <option value="No">No</option>
                                                                    </AvField>
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup className="mb-2" row>
                                                                <Col lg="12">
                                                                    <AvField type="select" name="sale_status" label="Status" onChange={this.statusHandler} required>
                                                                        <option defaultValue>Select status</option>
                                                                        <option value="Chargeback">Chargeback</option>
                                                                        <option value="Charged and Closed">Charged & Closed</option>
                                                                        <option value="Pending">Pending</option>
                                                                        <option value="Refunded">Refunded</option>
                                                                    </AvField>
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup className="mb-2" row>
                                                                <Col lg="12">
                                                                    <AvField name="vendor" label="Vendor" value="" className="form-control" type="text" required />
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            {this.state.closed_reason ? <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-4" row>
                                                        <Col lg="12">
                                                            <AvField type="select" name="is_closed" label="Is Closed?" onChange={this.closedHandler} required>
                                                                <option defaultValue>Select status</option>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </AvField>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row> : null}

                                            {this.state.closed_reason_yes ? <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-4" row>
                                                        <Col lg="12">
                                                            <AvField name="closed_reason" label="Reason for Closed" className="form-control" type="textarea" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row> : null}

                                            {this.state.pending_reason ? <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-4" row>
                                                        <Col lg="12">
                                                            <AvField name="pending_reason" label="Reason for Pending" className="form-control" type="textarea" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row> : null}

                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="notes" label="Technician Notes" className="form-control" type="textarea" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="billing_information" label="Billing Information" className="form-control" type="textarea" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="10">
                                                    <AvField name="user_id_fk" value={this.state.current_userID} type="hidden" />
                                                    <AvField name="authtoken" value={this.state.token} type="hidden" />
                                                    <Button type="submit" color="primary">Submit</Button>
                                                </Col>
                                            </Row>

                                        </AvForm>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default CreateSale;