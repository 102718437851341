import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import { api_url, getAuthHeaders  } from '../../helpers/fakebackend_helper';

class StackedColumnChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    height: 359,
                    type: "bar",
                    stacked: !0,
                    toolbar: {
                        show: 1
                    },
                    zoom: {
                        enabled: !0
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: !1,
                        columnWidth: "70%",
                        // endingShape: "rounded"
                    }
                },
                dataLabels: {
                    enabled: !1
                },
                xaxis: {
                    categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                },
                colors: ["#556ee6", "#f1b44c", "#34c38f"],
                legend: {
                    position: "bottom"
                },
                fill: {
                    opacity: 1
                }
            },
            series: []
        }
    }

    componentDidMount() {
        const headers = getAuthHeaders();
          axios.get(api_url+'/graphs/thisyear/', {headers: headers})
            .then(res => {
                this.setState({
                    series: [
                        {
                            name: "Sales",
                            data: res.data.data
                        }
                    ]
                })
            });

            axios.get(api_url+'/graphs/thisweek/', {headers: headers})
            .then(res => {
                
            });
    }

    render() {
        return (
            <React.Fragment>
                <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height="400" />
            </React.Fragment>
        );
    }
}

export default StackedColumnChart;
