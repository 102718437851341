import React, { Component } from "react";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
// import "./datatables.scss";
import axios from 'axios';
import { api_url, getAuthHeaders } from '../../helpers/fakebackend_helper';

class Logs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      rows: []
    };
  }

  componentDidMount() {
    const headers = getAuthHeaders();

    axios.get(api_url + '/log/', { headers: headers })
      .then(res => {
        console.log(res);

        if (res.success) {
          const logArr = res.data.data;
          const list = logArr.map((item) => {
            let newRow = {
              sr: '*',
              log: item.log + ' by ' + item.upro_first_name + item.upro_last_name + ' at ' + item.created_at
            };
            this.setState((state) => ({
              rows: [
                ...state.rows,
                newRow
              ],
            }));
          });
          this.setState({
            data: {
              columns: [
                { label: "*", field: "sr", sort: "asc", width: 150 },
                { label: "Log", field: "log", sort: "asc", width: 150 },
              ],
              rows: this.state.rows
            }
          });
        }
      });
  }

  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <div className="container-fluid">

            <Breadcrumbs title="Logs" breadcrumbItem="Manage Logs" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>

                    <MDBDataTable responsive striped bordered data={this.state.data} />

                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Logs;
