import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, FormGroup, Button, Alert, Table, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from 'axios';
import { Link } from "react-router-dom";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "./datatables.scss";
import { getLoggedInUserRole, api_url, getAuthHeaders } from '../../helpers/fakebackend_helper';

const loaderStyle = {
  position: 'fixed',
  zIndex: '1111111111111111111111',
  left: '0px',
  top: '0px',
  height: '100%',
  width: '100%',
  backgroundColor: 'rgba(255,255,255,0.8)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const tableHeaders = [
  { title: 'Ticket Number' },
  { title: 'First Name' },
  { title: 'Last Name' },
  { title: 'Technician Notes' },
  { title: 'Action' },
]

const pageSizes = [10, 50, 100];
const pageNumbers = 5;

const SalesList = () => {
  const [userRole, setUserRole] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [searchError, setSearchError] = useState(false);
  const [search, setSearch] = useState('');
  const [keyword, setKeyword] = useState('');
  const [records, setRecords] = useState([]);
  const [selectedPageSize, setSelectedPageSize] = useState(10);
  const [pages, setPages] = useState([]);
  const [allPages, setAllPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [textLoader, setTextLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileErrors, setFileErrors] = useState([]);
  const [fileMessages, setFileMessages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const toggleModal = () => {
    setShowModal(!showModal);
    setSelectedFile(null);
    setFileErrors([]);
    setFileMessages([]);
  }

  const getSales = () => {
    setTextLoader(true);
    let queryString = `?page=${currentPage}&perpage=${selectedPageSize}`;
    if (keyword) {
      queryString += `&search=${keyword}`;
    }
    if (fromDate) {
      queryString += `&fromDate=${fromDate}`;
    }
    if (toDate) {
      queryString += `&toDate=${toDate}`;
    }
    axios.get(api_url + `/sale/${queryString}`, { headers: getAuthHeaders() })
      .then(res => {
        setRecords(res.data.data);
        setTotalRows(res.data.totalRows);
      }).finally((res) => setTextLoader(false));
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setKeyword(search)
    }, 1200)

    return () => clearTimeout(timer)
  }, [search])

  useEffect(() => {
    setUserRole(getLoggedInUserRole());
    getSales();
  }, [currentPage, selectedPageSize, keyword])

  useEffect(() => {
    if (totalRows) {
      const count = totalRows / selectedPageSize;
      const pageArray = [];
      for (let i = 1; i <= count; i++) {
        pageArray.push(i);
      }
      setAllPages(pageArray);
      setPages(pageArray?.length > 5 ? [1, 2, 3, 4, 5] : pageArray);
    }
  }, [totalRows, selectedPageSize]);

  const handlePageChange = (type) => {
    if (type === "prev") {
      const pageToChange = currentPage - 1;
      const index = allPages.indexOf(pageToChange);
      const newArr = [...allPages].slice(
        pageNumbers > index ? pageNumbers - index : index - pageNumbers,
        index + 1 >= pageNumbers ? index : 0,
      );
      setPages(newArr);
      setCurrentPage(pageToChange);
    } else {
      const pageToChange = currentPage + 1;
      const index = allPages.indexOf(pageToChange);
      const newArr = [...allPages].slice(index, pageNumbers + index);
      setPages(newArr);
      setCurrentPage(pageToChange);
    }
  };

  const handlePageLinkChange = (page) => {
    setCurrentPage(page);
  };

  const handleFromDate = (e) => {
    setFromDate(e.target.value)
  }

  const handleToDate = (e) => {
    setToDate(e.target.value)
  }

  const handlePageSize = (e) => {
    setSelectedPageSize(e.target.value)
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
  }

  const handleSearchSubmit = () => {
    if (fromDate || toDate) {
      if (fromDate == '' || toDate == '') {
        setSearchError('Please select both dates.');
      } else {
        setSearchError(false);
        setCurrentPage(1);
        getSales();
      }
    }
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  }

  const handleUpload = () => {
    if (selectedFile) {
      setTextLoader(true);
      const formData = new FormData();
      formData.append("sales_file", selectedFile);

      axios.post(api_url + `/sale/importsales`, formData, { headers: getAuthHeaders() })
        .then(res => {
          if(res?.data?.success){
            setFileMessages(['File imported successfully']);
            setTimeout(() => {
              toggleModal();
            }, 2000)
          }else{
            setFileErrors([res?.data?.message]);
          }
        }).finally((res) => setTextLoader(false));
    } else {
      alert('Please select a file first');
    }
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          {textLoader ? (
            <div style={loaderStyle}>Loading...</div>
          ) : null}
          <Breadcrumbs title="Sales" breadcrumbItem="Manage Sales" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <span className="btn btn-primary waves-effect waves-light float-right mb-2" style={{ marginLeft: 10 }} onClick={toggleModal}>Bulk Import</span>

                  {userRole == 1 || userRole == 3 ? <div className="clearfix">
                    <Link to="/add-sale" id="" className="btn btn-primary waves-effect waves-light float-right mb-2">Add New</Link>
                  </div> : null}

                  {searchError ? <Alert className="col-lg-12" color="danger">{searchError}</Alert> : null}
                  {userRole == 1 ? <FormGroup className="mb-4" row>
                    <Col lg="2">
                      <label>From</label>
                      <input className="form-control" name="fromDate" type="date" value={fromDate} onChange={handleFromDate} />
                    </Col>
                    <Col lg="2">
                      <label>To</label>
                      <input className="form-control" name="toDate" type="date" value={toDate} onChange={handleToDate} />
                    </Col>
                    <Col lg="2">
                      <Button type="button" color="primary" onClick={handleSearchSubmit} style={{ marginTop: '27px' }}>Search</Button>
                    </Col>
                    <Col lg="3"></Col>
                    <Col lg="3">
                      <label>Search</label>
                      <input className="form-control" name="search" type="text" value={search} onChange={handleSearchChange} placeholder="Search" />
                    </Col>
                  </FormGroup> : null}

                  <Table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        {tableHeaders?.map((header) => (
                          <th>{header.title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {records?.length ? records?.map((row) => (
                        <tr>
                          <td><Link to={`/sale-details/${row?.id}`}>{row?.ticket_no}</Link></td>
                          <td>{row?.first_name}</td>
                          <td>{row?.last_name}</td>
                          <td>{row?.notes}</td>
                          <td>
                            <Link to={`/sale/${row?.id}`}>
                              <i className="bx bx-edit font-size-22"></i>
                            </Link>
                            <Link to={`/sale-details/${row?.id}`}>
                              <i className="bx bx-detail font-size-22 ml-2"></i>
                            </Link>
                          </td>
                        </tr>
                      )) : null}
                    </tbody>
                  </Table>

                  {totalRows ? (
                    <Row>
                      <Col sm="2">
                        <label>Show entries</label>
                        <select className="form-control" onChange={handlePageSize}>
                          {pageSizes?.map((size) => (
                            <option value={size} selected={selectedPageSize == size}>{size}</option>
                          ))}
                        </select>
                      </Col>
                      <Col className="d-flex flex-row-reverse">
                        <Pagination>
                          <PaginationItem>
                            <PaginationLink
                              onClick={() => handlePageChange("prev")}
                              previous
                              disabled={pages?.includes(1)}
                            />
                          </PaginationItem>
                          {pages && pages?.length
                            ? pages?.map((page) => (
                              <PaginationItem active={currentPage == page}>
                                <PaginationLink href="#" onClick={() => handlePageLinkChange(page)}>
                                  {page}
                                </PaginationLink>
                              </PaginationItem>
                            ))
                            : null}

                          <PaginationItem>
                            <PaginationLink
                              onClick={() => handlePageChange("next")}
                              next
                              disabled={pages?.includes(allPages?.length)}
                            />
                          </PaginationItem>
                        </Pagination>
                      </Col>
                    </Row>
                  ) : null}

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>


      <Modal isOpen={showModal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1" toggle={toggleModal}>
        <div className="modal-content">
          <ModalHeader toggle={toggleModal}>
            Bulk Import
          </ModalHeader >
          <ModalBody>
            <input type="file" onChange={handleFileUpload} accept=".csv" />
            {fileErrors?.length ? (
              <div>
                <ul style={{ color: 'red', listStyle: 'none', padding: 0 }}>
                  {fileErrors.map((errorItem) => {
                    return (
                      <li>{errorItem}</li>
                    )
                  })}
                </ul>
              </div>
            ) : null}
            {fileMessages?.length ? (
              <div>
                <ul style={{ color: 'green', listStyle: 'none', padding: 0 }}>
                  {fileMessages.map((errorItem) => {
                    return (
                      <li>{errorItem}</li>
                    )
                  })}
                </ul>
              </div>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={toggleModal}>Close</Button>
            <Button type="button" color="primary" onClick={handleUpload}>Import</Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  )
}

export default SalesList
