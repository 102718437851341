import React, { Component } from 'react';
import { Container, Row, Col, Card, Alert, CardBody, Media, Button, FormGroup, CardTitle } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// Redux
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumb from '../../components/Common/Breadcrumb';

import avatar from '../../assets/images/users/avatar-1.jpg';
// actions
import { editProfile } from '../../store/actions';

import { getLoggedInUser, api_url, getAuthHeaders } from '../../helpers/fakebackend_helper';
import axios from 'axios';

class UserProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current_userID: 0,
            token: '',
            success: '',
            error: '',
            userID: 0,
            user: '',
            loader: false
        }
        this.handleValidSubmit = this.handleValidSubmit.bind(this);
    }

    // handleValidSubmit
    handleValidSubmit = (event, values) => {
        this.setState({
            loader: true
        });
        const headers = getAuthHeaders();

        axios.put(api_url + '/user/' + this.state.userID, values, { headers: headers })
            .then(res => {
                if (res.data.success) {
                    this.setState({ success: res.data.message });
                    this.setState({
                        loader: false
                    });
                    setTimeout(() => {
                        this.setState({
                            success: '',
                            error: '',
                            loader: false
                        });
                    }, 4000);
                } else {
                    this.setState({ error: res.data.errors });
                }
            });
    }

    componentDidMount() {
        const userID = getLoggedInUser().data.uacc_id;
        const headers = getAuthHeaders();
        this.setState({
            current_userID: userID,
            token: getLoggedInUser().data.authtoken,
            userID: userID
        });
        axios.get(api_url + '/user/' + userID, { headers: headers })
            .then(res => {
                this.setState({
                    user: res.data.data
                });
            });
    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        <Breadcrumb title="Olympus" breadcrumbItem="Profile" />

                        <Row>
                            <Col lg="12">
                                {this.props.error && this.props.error ? <Alert color="danger">{this.props.error}</Alert> : null}
                                {this.props.success && this.props.success ? <Alert color="success">{this.props.success}</Alert> : null}

                                <Card>
                                    <CardBody>
                                        <Media>
                                            <div className="mr-3">
                                                <img src={avatar} alt="" className="avatar-md rounded-circle img-thumbnail" />
                                            </div>
                                            <Media body className="align-self-center">
                                                <div className="text-muted">
                                                    <h5>{this.state.user.upro_first_name + ' ' + this.state.user.upro_last_name}</h5>
                                                    <p className="mb-1">{this.state.user.uacc_email}</p>
                                                </div>
                                            </Media>
                                        </Media>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Card>
                            <CardBody>
                                <AvForm ref={this.formRef} onValidSubmit={this.handleValidSubmit}>

                                    {this.state.error && this.state.error ? <Alert className="col-lg-12" color="danger">{this.state.error}</Alert> : null}
                                    {this.state.success && this.state.success ? <Alert className="col-lg-12" color="success">{this.state.success}</Alert> : null}

                                    {this.state.loader && this.state.loader ? <div className="my-spinner"><div className="spinner-border text-primary m-1" role="status"><span className="sr-only">Loading...</span></div></div> : null}


                                    <Row>
                                        <Col lg="12">
                                            <FormGroup className="mb-2" row>
                                                <Col lg="12">
                                                    <AvField type="select" name="uacc_group_fk" label="User Type" value={this.state.user.uacc_group_fk} readOnly={true} disabled={true}>
                                                        <option defaultValue>Select type</option>
                                                        <option value="1">Admin</option>
                                                        <option value="2">Customer Service</option>
                                                        <option value="3">Sale Agent</option>
                                                        <option value="4">Technician</option>
                                                    </AvField>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup className="mb-2" row>
                                                <Col lg="12">
                                                    <AvField name="upro_first_name" label="First Name" value={this.state.user.upro_first_name} className="form-control" type="text" required />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup className="mb-2" row>
                                                <Col lg="12">
                                                    <AvField name="upro_last_name" label="Last Name" value={this.state.user.upro_last_name} className="form-control" type="text" required />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <hr />
                                    <CardTitle className="mb-4 mt-2">Login Information</CardTitle>

                                    <Row>
                                        <Col lg="6">
                                            <FormGroup className="mb-2" row>
                                                <Col lg="12">
                                                    <AvField name="uacc_username" label="Username" value={this.state.user.uacc_username} className="form-control" type="text" required />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup className="mb-2" row>
                                                <Col lg="12">
                                                    <AvField type="select" name="uacc_active" value={this.state.user.uacc_active} label="Active" required>
                                                        <option value="1">Yes</option>
                                                        <option value="0">No</option>
                                                    </AvField>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup className="mb-2" row>
                                                <Col lg="12">
                                                    <AvField name="uacc_password" label="Password" value="" className="form-control" type="password" />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup className="mb-2" row>
                                                <Col lg="12">
                                                    <AvField name="confirm_password" label="Confirm Password" value="" className="form-control" type="password" validate={{ match: { value: 'uacc_password' } }} />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg="10">
                                            <AvField name="id" value={this.state.user.uacc_id} type="hidden" />
                                            <Button type="submit" color="primary">Submit</Button>
                                        </Col>
                                    </Row>

                                </AvForm>

                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { error, success } = state.Profile;
    return { error, success };
}

export default withRouter(connect(mapStatetoProps, { editProfile })(UserProfile));

