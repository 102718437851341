import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, Media } from "reactstrap";
import StackedColumnChart from "./StackedColumnChart";
import WelcomeComp from "./WelcomeComp";
import LatestTranaction from "./LatestTranaction";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { withNamespaces } from 'react-i18next';
import axios from 'axios';
import { getLoggedInUserRole, api_url, getAuthHeaders } from '../../helpers/fakebackend_helper';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reports: {},
            email: [
                { title: "Week", linkto: "#", isActive: true },
                { title: "Month", linkto: "#", isActive: false },
                { title: "Year", linkto: "#", isActive: false }
            ],
            userRole: 0
        };
    }

    componentDidMount() {
        this.setState({
            userRole: getLoggedInUserRole()
        });
        const headers = getAuthHeaders();
        axios.get(api_url + '/graphs/adminDashboard/', { headers: headers })
            .then(res => {

                const reportsArr = res.data.data;

                this.setState((state) => ({
                    reports: reportsArr,
                }));

                console.log(this.state.reports);

            });
    }

    render() {
        const reportsList = Object.keys(this.state.reports).map(key =>
            <Col md="3" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                    <CardBody>
                        <Media>
                            <Media body>
                                <p className="text-muted font-size-24 font-weight-medium">{this.state.reports[key]}</p>
                                <h4 className="mb-0" style={{ textTransform: 'capitalize' }}>{key}</h4>
                            </Media>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                <span className="avatar-title">
                                    <i className={"bx bx-info-circle font-size-24"}></i>
                                </span>
                            </div>
                        </Media>
                    </CardBody>
                </Card>
            </Col>
        )
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumb */}
                        <Breadcrumbs title={this.props.t('Dashboard')} breadcrumbItem={this.props.t('Dashboard Admin')} />

                        <Row>
                            {this.state.userRole == 1 ? reportsList : null}
                        </Row>
                        <Row>
                            <Col xl="4">
                                <WelcomeComp />
                                {/* <MonthlyEarning /> */}
                            </Col>
                            <Col xl="8">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4 float-sm-left">
                                            Yearly Sales
                                        </CardTitle>
                                        <div className="clearfix"></div>
                                        <StackedColumnChart />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg="12">
                                <LatestTranaction />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(Dashboard);
