import axios from 'axios';

const api_url = 'https://api.olympiasec.com/api';

export const getAuthHeaders = () => {
    return {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("authUser"))?.data?.authtoken}`
    }
}

const headers = getAuthHeaders();

// Gets the logged in user data from local session 
const getLoggedInUser = () => {
    const user = localStorage.getItem('authUser');
    if (user)
        return JSON.parse(user);
    return null;
}

const getUserRoleByID = (id) => {
    if (id == 1) {
        return 'Admin'
    } else if (id == 2) {
        return 'Customer Service'
    } else if (id == 3) {
        return 'Sale Agent'
    } else if (id == 4) {
        return 'Technician'
    }
}

const getLoggedInUserName = () => {
    const user = localStorage.getItem('authUser');
    if (user)
        var username = JSON.parse(user);
    return username.data.uacc_username.charAt(0).toUpperCase() + username.data.uacc_username.slice(1);
    return null;
}

const getLoggedInUserRole = () => {
    const user = localStorage.getItem('authUser');
    if (user)
        var userrle = JSON.parse(user);
    if (userrle) {
        return userrle.data.uacc_group_fk;
    } else {
        return null;
    }
    return null;
}

const getLoggedInUserID = () => {
    const user = localStorage.getItem('authUser');
    if (user)
        var userID = JSON.parse(user);
    return JSON.parse(userID.data.uacc_id);
    return null;
}

const getUserRole = () => {
    const user = localStorage.getItem('authUser');
    if (user)
        var userRole = JSON.parse(user);
    userRole = userRole.data.uacc_group_fk;
    if (userRole == 1) {
        return 'Admin'
    } else if (userRole == 2) {
        return 'Customer Service'
    } else if (userRole == 3) {
        return 'Sale Agent'
    } else if (userRole == 4) {
        return 'Technician'
    }
    return null;
}

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
}

// Register Method
const postFakeRegister = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        var message;
        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 404: message = "Sorry! the page you are looking for could not be found"; break;
                case 500: message = "Sorry! something went wrong, please contact our support team"; break;
                case 401: message = "Invalid credentials"; break;
                default: message = err[1]; break;
            }
        }
        throw message;
    });

}

// Login Method
const postFakeLogin = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}

// postForgetPwd 
const postFakeForgetPwd = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}


// Login Method
const postJwtProfile = (url, data) => {
    return axios.post(url, data, { headers: headers }).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}

const postFakeProfile = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}

// Register Method
const postJwtRegister = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        var message;
        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 404: message = "Sorry! the page you are looking for could not be found"; break;
                case 500: message = "Sorry! something went wrong, please contact our support team"; break;
                case 401: message = "Invalid credentials"; break;
                default: message = err[1]; break;
            }
        }
        throw message;
    });
}

// Login Method
const postJwtLogin = (url, data) => {
    return axios.post(api_url + '/login/', data, headers).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}

// postForgetPwd 
const postJwtForgetPwd = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        throw err[1];
    });
}


export { getLoggedInUser, isUserAuthenticated, postFakeRegister, postFakeLogin, postFakeProfile, postFakeForgetPwd, postJwtRegister, postJwtLogin, postJwtForgetPwd, postJwtProfile, getLoggedInUserName, getUserRole, getLoggedInUserRole, getLoggedInUserID, getUserRoleByID, api_url }