import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import axios from 'axios';
import { getLoggedInUserRole, api_url, getAuthHeaders } from '../../helpers/fakebackend_helper';

class ViewSale extends Component {
    constructor() {
        super();
        this.state = {
            sale: [], userRole: 0, success: '', error: '', loader: false, open_notes: false, success_dlg: false,
            dynamic_title: "", dynamic_description: "", notes: "", notesError: "", headers: {},
            open_sale_upgrade: false, upgrade_notes: "", upgrade_amount: "",
            upgrade_error: "", sales_notes_list: {}, sales_notes_rows: [],
            sales_upgrades_list: {}, sales_upgrades_rows: [], tech_options: [],
            sale_by: '', payment_by: '', closing: '', altphone: '', cxid: '', gateway: '', bttr: '', vendor: '', billing_information: ''
        }
        this.amountRef = React.createRef();
        this.noteRef = React.createRef();
    }

    renderRow = (heading, text) => {
        return (
            <div>
                <p><strong>{heading}: </strong> {text}</p>
            </div>
        )
    }

    componentDidMount() {
        this.setState({
            userRole: getLoggedInUserRole(),
            headers: getAuthHeaders()
        });
        const saleID = this.props.match.params.saleId;
        //Get current sale
        axios.get(api_url + '/sale/singleSale/' + saleID, { headers: getAuthHeaders() })
            .then(res => {
                this.setState({
                    sale: res.data.data
                });
            });

        //Get sale notes
        axios.get(api_url + '/notes/' + saleID, { headers: getAuthHeaders() })
            .then(res => {
                const salesNotesArr = res.data.data;
                salesNotesArr.map((item) => {
                    let newNoteRow = {
                        name: item.upro_first_name + ' ' + item.upro_last_name,
                        notes: item.notes
                    };
                    this.setState((state) => ({
                        sales_notes_rows: [
                            ...state.sales_notes_rows,
                            newNoteRow
                        ],
                    }));
                });
                this.setState({
                    sales_notes_list: {
                        columns: [
                            { label: "Name", field: "name", sort: "asc", width: 150 },
                            { label: "Notes", field: "notes", sort: "asc", width: 150 }
                        ],
                        rows: this.state.sales_notes_rows
                    }
                });
            });

        //Get sale upgrades
        axios.get(api_url + '/upgrade/' + saleID, { headers: getAuthHeaders() })
            .then(res => {

                const upgradesNotesArr = res.data.data;
                upgradesNotesArr.map((item) => {
                    let newUpgradeRow = {
                        name: item.upro_first_name + ' ' + item.upro_last_name,
                        amount: '₹' + item.amount,
                        notes: item.notes
                    };
                    this.setState((state) => ({
                        sales_upgrades_rows: [
                            ...state.sales_upgrades_rows,
                            newUpgradeRow
                        ],
                    }));
                });
                this.setState({
                    sales_upgrades_list: {
                        columns: [
                            { label: "Name", field: "name", sort: "asc", width: 250 },
                            { label: "Amount", field: "amount", sort: "asc", width: 150 },
                            { label: "Notes", field: "notes", sort: "asc", width: 150 }
                        ],
                        rows: this.state.sales_upgrades_rows
                    }
                });
            });

        // tech_id_fk
        axios.get(api_url + '/user/techUser/', { headers: getAuthHeaders() })
            .then(res => {
                const tech_users = res.data.data;
                if (tech_users) {
                    const allUsers = [];
                    tech_users.map((item) => {
                        let newRow = {
                            tech_id_fk: item.uacc_id,
                            name: item.upro_first_name + ' ' + item.upro_last_name
                        };
                        allUsers.push(newRow);
                    });
                    this.setState({
                        tech_options: allUsers,
                    });
                }
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Sales" breadcrumbItem="Sale Details" />
                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>

                                        <div className="clearfix mb-2">
                                            {this.state.userRole == 1 || this.state.userRole == 3 ? <Link to="/sales" id="" className="btn btn-primary waves-effect waves-light float-right mb-2">Manage Sales</Link> : null}

                                            {this.state.userRole == 1 || this.state.userRole == 3 ? <Link to="/add-sale" id="" className="btn btn-primary waves-effect waves-light float-right mb-2 mr-2">Add New</Link> : null}
                                        </div>

                                        <Row>
                                            <Col lg="6">
                                                {this.renderRow('First Name', this.state.sale.first_name)}
                                                {this.renderRow('Last Name', this.state.sale.last_name)}
                                                {this.renderRow('Email', this.state.sale.email)}
                                                {this.renderRow('Phone', this.state.sale.phone)}
                                                {this.renderRow('Alt Phone', this.state.sale.altphone)}
                                                {this.renderRow('CX ID', this.state.sale.cxid)}
                                                {this.renderRow('Gateway', this.state.gateway)}
                                                {this.renderRow('BTTR', this.state.sale.bttr)}
                                                {this.renderRow('Zipcode', this.state.sale.zip_code)}
                                                {this.renderRow('Address', this.state.sale.address)}
                                                {this.renderRow('KYC Status', this.state.sale.kyc_status)}
                                            </Col>
                                            <Col lg="6">
                                                {this.renderRow('Technician', this.state.tech_options.map((tech_user) =>
                                                    <>{
                                                        this.state.sale.tech_id_fk === tech_user.tech_id_fk ? tech_user.name : ''
                                                    }</>
                                                ))}
                                                {this.renderRow('Sale By', this.state.sale.sale_by)}
                                                {this.renderRow('Payment By', this.state.sale.payment_by)}
                                                {this.renderRow('Close By', this.state.sale.closing)}
                                                {this.renderRow('Service', this.state.sale.plan)}
                                                {this.renderRow('Amount', this.state.sale.amount)}
                                                {this.renderRow('Date', this.state.sale.sale_date)}
                                                {this.renderRow('Status', this.state.sale.sale_status)}
                                                {this.renderRow('Vendor', this.state.sale.vendor)}
                                                {this.state.sale.is_closed ?
                                                    <>{this.renderRow('Is Closed?', this.state.sale.is_closed == 1 ? 'Yes' : 'No')}</>
                                                    : null}
                                                {this.state.sale.closed_reason ? <>
                                                    {this.renderRow('Reason for Closed', this.state.sale.closed_reason)}
                                                </> : null}
                                                {this.state.sale.pending_reason ? <>
                                                    {this.renderRow('Reason for Pending', this.state.sale.pending_reason)}
                                                </> : null}
                                                {this.renderRow('Technician Notes', this.state.sale.notes)}
                                                {this.renderRow('Billing Information', this.state.sale.billing_information)}
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Card>
                            </Col>

                            {/* Sale notes */}
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">
                                            Sale Notes
                                        </CardTitle>
                                        <div className="table-responsive first-td-capital">
                                            <MDBDataTable responsive striped bordered data={this.state.sales_notes_list} />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            {/* Sale upgrades */}
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">
                                            Sale Upgrades
                                        </CardTitle>
                                        <div className="table-responsive first-td-capital">
                                            <MDBDataTable responsive striped bordered data={this.state.sales_upgrades_list} />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ViewSale;