import React, { Component } from "react";

import { Card, CardBody, CardTitle } from "reactstrap";
import { getLoggedInUser, api_url, getAuthHeaders } from '../../helpers/fakebackend_helper';

import axios from 'axios';

class LatestTranaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            myRanking: false,
            myRank: 0,
            myAmount: 0,
            myX: 0
        };
    }

    componentDidMount() {
        var userid = null;
        if (getLoggedInUser().data.uacc_group_fk == 3) {
            userid = getLoggedInUser().data.uacc_id;
        }
        axios.get(api_url + '/graphs/top20/' + userid, { headers: getAuthHeaders() })
            .then(res => {

                const salesArr = res.data.data;
                let rank = 1;
                if (salesArr) {
                    salesArr.map((item) => {
                        let newRow = {
                            id: item.upro_id,
                            rank: rank++,
                            name: item.upro_first_name + ' ' + item.upro_last_name,
                            total: item.total
                        };
                        this.setState((state) => ({
                            rows: [
                                ...state.rows,
                                newRow
                            ],
                        }));
                    });
                }

                if (res.data.myrating) {
                    this.setState({
                        myRanking: true,
                        myRank: res.data.myrating.rank,
                        myAmount: res.data.myrating.total,
                        myX: res.data.myrating.user_id_fk
                    })
                }
            });
    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4">
                            Ranking - TOP 20
                            {this.state.myRanking ?
                                <div className="float-right">
                                    My Rank : <i className="bx bx-star"></i>{this.state.myRank} &nbsp;&nbsp; Amount:&nbsp; <i class="bx bx-dollar"></i>{this.state.myAmount}
                                </div> : null
                            }
                        </CardTitle>
                        <div className="table-responsive">
                            <table className="table table-centered table-nowrap mb-0 capitalize-table">
                                <thead className="thead-light">
                                    <tr>
                                        <th>Rank</th>
                                        <th>Name</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.rows.map((transaction, index) =>
                                            <tr key={index} className={this.state.myX == transaction.user_id_fk ? 'highlight' : transaction.user_id_fk}>
                                                <td><i className="bx bx-star"></i> {transaction.rank}</td>
                                                <td>{transaction.name}</td>
                                                <td><i className="bx bx-dollar"></i>{transaction.total}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default LatestTranaction;
