import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Table, Alert, FormGroup, Button, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import axios from 'axios';
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getUserRoleByID, api_url, getAuthHeaders, getLoggedInUserRole } from '../../helpers/fakebackend_helper';

const loaderStyle = {
  position: 'fixed',
  zIndex: '11',
  left: '0px',
  top: '0px',
  height: '100%',
  width: '100%',
  backgroundColor: 'rgba(255,255,255,0.8)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}
const tableHeaders = [
  { title: 'Name' },
  { title: 'Username' },
  { title: 'User' },
  { title: 'Action' },
]
const pageSizes = [10, 50, 100];
const pageNumbers = 5;

const UsersList = () => {
  const [userRole, setUserRole] = useState('');
  const [searchError, setSearchError] = useState(false);
  const [search, setSearch] = useState('');
  const [keyword, setKeyword] = useState('');
  const [records, setRecords] = useState([]);
  const [selectedPageSize, setSelectedPageSize] = useState(10);
  const [pages, setPages] = useState([]);
  const [allPages, setAllPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [textLoader, setTextLoader] = useState(false);
  const [userDeleted, setUserDeleted] = useState(false);
  const [deleteUser, setDeleteUser] = useState(false);
  const [errorDlg, setErrorDlg] = useState(false);
  const [userID, setUserID] = useState(0);
  const [successDlg, setSuccessDlg] = useState(false);
  const [dynamicDescription, setDynamicDescription] = useState('');
  const [dynamicTitle, setDynamicTitle] = useState('');

  const getUsers = () => {
    setTextLoader(true);
    let queryString = `?page=${currentPage}&perpage=${selectedPageSize}`;
    if (keyword) {
      queryString += `&search=${keyword}`;
    }
    axios.get(api_url + `/user/${queryString}`, { headers: getAuthHeaders() })
      .then(res => {
        setRecords(res.data.data);
        setTotalRows(res.data.totalRows);
      }).finally((res) => setTextLoader(false));
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setKeyword(search)
    }, 1200)

    return () => clearTimeout(timer)
  }, [search])

  useEffect(() => {
    setUserRole(getLoggedInUserRole());
    getUsers();
  }, [currentPage, selectedPageSize, keyword])

  useEffect(() => {
    if (totalRows) {
      const count = totalRows / selectedPageSize;
      const pageArray = [];
      for (let i = 1; i <= count; i++) {
        pageArray.push(i);
      }
      setAllPages(pageArray);
      setPages(pageArray?.length > 5 ? [1, 2, 3, 4, 5] : pageArray);
    }
  }, [totalRows, selectedPageSize]);

  const handlePageChange = (type) => {
    if (type === "prev") {
      const pageToChange = currentPage - 1;
      const index = allPages.indexOf(pageToChange);
      const newArr = [...allPages].slice(
        pageNumbers > index ? pageNumbers - index : index - pageNumbers,
        index + 1 >= pageNumbers ? index : 0,
      );
      setPages(newArr);
      setCurrentPage(pageToChange);
    } else {
      const pageToChange = currentPage + 1;
      const index = allPages.indexOf(pageToChange);
      const newArr = [...allPages].slice(index, pageNumbers + index);
      setPages(newArr);
      setCurrentPage(pageToChange);
    }
  };

  const handlePageLinkChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSize = (e) => {
    setSelectedPageSize(e.target.value)
  }

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
  }

  const handleSearchSubmit = () => {
    setSearchError(false);
    setCurrentPage(1);
    getUsers();
  }

  const handleDelete = (id) => {
    setDeleteUser(true);
    setUserID(id);
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          {textLoader ? (
            <div style={loaderStyle}>Loading...</div>
          ) : null}
          <Breadcrumbs title="Users" breadcrumbItem="Manage Users" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>

                  <div className="clearfix">
                    <Link to="/add-user" id="" className="btn btn-primary waves-effect waves-light float-right mb-2">Add New</Link>
                  </div>

                  {searchError ? <Alert className="col-lg-12" color="danger">{searchError}</Alert> : null}
                  {userRole == 1 ? <FormGroup className="mb-4" row>
                    <Col lg="3">
                      <label>Search</label>
                      <input className="form-control" name="search" type="text" value={search} onChange={handleSearchChange} placeholder="Search" />
                    </Col>
                    <Col lg="2">
                      <Button type="button" color="primary" onClick={handleSearchSubmit} style={{ marginTop: '27px' }}>Search</Button>
                    </Col>
                    <Col lg="3"></Col>
                  </FormGroup> : null}

                  <Table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        {tableHeaders?.map((header) => (
                          <th>{header.title}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {records?.length ? records?.map((row) => (
                        <tr>
                          <td>
                            <Link to={`/user-details/${row?.uacc_id}`}>
                              {row?.upro_first_name + ' ' + row?.upro_last_name}
                            </Link>
                          </td>
                          <td>{row?.uacc_username}</td>
                          <td>{getUserRoleByID(row?.uacc_group_fk)}</td>
                          <td>
                            <Link to={`/user/${row?.uacc_id}`}>
                              <i className="bx bx-edit font-size-22"></i>
                            </Link>
                            <Link to={`/user-details/${row?.uacc_id}`}>
                              <i className="bx bx-detail font-size-22 ml-2"></i>
                            </Link>&nbsp;
                            <span onClick={() => handleDelete(row?.uacc_id)}>
                              <i className="bx bx-trash font-size-22"></i>
                            </span>
                          </td>
                        </tr>
                      )) : null}
                    </tbody>
                  </Table>

                  {totalRows ? (
                    <Row>
                      <Col sm="2">
                        <label>Show entries</label>
                        <select className="form-control" onChange={handlePageSize}>
                          {pageSizes?.map((size) => (
                            <option value={size} selected={selectedPageSize == size}>{size}</option>
                          ))}
                        </select>
                      </Col>
                      <Col className="d-flex flex-row-reverse">
                        <Pagination>
                          <PaginationItem>
                            <PaginationLink
                              onClick={() => handlePageChange("prev")}
                              previous
                              disabled={pages?.includes(1)}
                            />
                          </PaginationItem>
                          {pages && pages?.length
                            ? pages?.map((page) => (
                              <PaginationItem active={currentPage == page}>
                                <PaginationLink href="#" onClick={() => handlePageLinkChange(page)}>
                                  {page}
                                </PaginationLink>
                              </PaginationItem>
                            ))
                            : null}

                          <PaginationItem>
                            <PaginationLink
                              onClick={() => handlePageChange("next")}
                              next
                              disabled={pages?.includes(allPages?.length)}
                            />
                          </PaginationItem>
                        </Pagination>
                      </Col>
                    </Row>
                  ) : null}

                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {deleteUser ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios.delete(`${api_url}/user/${userID}`, { headers: getAuthHeaders() })
              .then(res => {
                setDeleteUser(false);
                setSuccessDlg(true);
                setDynamicTitle('Deleted');
                setDynamicDescription('User has been deleted');
              });
          }}
          onCancel={() => {
            setDeleteUser(false);
            setErrorDlg(true);
            setDynamicTitle("Cancelled");
            setDynamicDescription("Delete operation cancelled!");
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      {successDlg ? (
        <SweetAlert
          success
          title={dynamicTitle}
          onConfirm={() => window.location.reload()}
        >
          {dynamicDescription}
        </SweetAlert>
      ) : null}
    </>
  )
}

export default UsersList;
