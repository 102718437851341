import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle, FormGroup, Button, Alert } from "reactstrap";

import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import axios from 'axios';
import { getLoggedInUserRole, getLoggedInUserID, api_url, getAuthHeaders } from '../../helpers/fakebackend_helper';
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import { MDBDataTable } from "mdbreact";

class EditSale extends Component {
    constructor() {
        super();
        this.state = {
            sale: [], userRole: 0, success: '', error: '', loader: false, open_notes: false, success_dlg: false,
            dynamic_title: "", dynamic_description: "", notes: "", notesError: "", headers: {},
            open_sale_upgrade: false, upgrade_notes: "", upgrade_amount: "",
            upgrade_error: "", sales_notes_list: {}, sales_notes_rows: [],
            sales_upgrades_list: {}, sales_upgrades_rows: [], tech_options: [],
            sale_by: '', payment_by: '', closing: '', altphone: '', cxid: '', gateway: '', bttr: '', vendor: '', billing_information: ''
        }
        this.amountRef = React.createRef();
        this.noteRef = React.createRef();
    }

    componentDidMount() {
        this.setState({
            userRole: getLoggedInUserRole(),
            headers: getAuthHeaders()
        });
        const saleID = this.props.match.params.saleId;
        //Get current sale
        axios.get(api_url + '/sale/singleSale/' + saleID, { headers: getAuthHeaders() })
            .then(res => {
                this.setState({
                    sale: res.data.data
                });
            });

        //Get sale notes
        axios.get(api_url + '/notes/' + saleID, { headers: getAuthHeaders() })
            .then(res => {
                const salesNotesArr = res.data.data;
                salesNotesArr.map((item) => {
                    let newNoteRow = {
                        name: item.upro_first_name + ' ' + item.upro_last_name,
                        notes: item.notes
                    };
                    this.setState((state) => ({
                        sales_notes_rows: [
                            ...state.sales_notes_rows,
                            newNoteRow
                        ],
                    }));
                });
                this.setState({
                    sales_notes_list: {
                        columns: [
                            { label: "Name", field: "name", sort: "asc", width: 150 },
                            { label: "Notes", field: "notes", sort: "asc", width: 150 }
                        ],
                        rows: this.state.sales_notes_rows
                    }
                });
            });

        //Get sale upgrades
        axios.get(api_url + '/upgrade/' + saleID, { headers: getAuthHeaders() })
            .then(res => {

                const upgradesNotesArr = res.data.data;
                upgradesNotesArr.map((item) => {
                    let newUpgradeRow = {
                        name: item.upro_first_name + ' ' + item.upro_last_name,
                        amount: '₹' + item.amount,
                        notes: item.notes
                    };
                    this.setState((state) => ({
                        sales_upgrades_rows: [
                            ...state.sales_upgrades_rows,
                            newUpgradeRow
                        ],
                    }));
                });
                this.setState({
                    sales_upgrades_list: {
                        columns: [
                            { label: "Name", field: "name", sort: "asc", width: 250 },
                            { label: "Amount", field: "amount", sort: "asc", width: 150 },
                            { label: "Notes", field: "notes", sort: "asc", width: 150 }
                        ],
                        rows: this.state.sales_upgrades_rows
                    }
                });
            });

        // tech_id_fk
        axios.get(api_url + '/user/techUser/', { headers: getAuthHeaders() })
            .then(res => {
                const tech_users = res.data.data;
                if (tech_users) {
                    tech_users.map((item) => {
                        let newRow = {
                            tech_id_fk: item.uacc_id,
                            name: item.upro_first_name + ' ' + item.upro_last_name
                        };
                        this.setState((state) => ({
                            tech_options: [
                                ...state.tech_options,
                                newRow
                            ],
                        }));
                    });
                }
            });
    }

    noteChangeHandler = (e) => {
        this.setState({
            notes: e.target.value
        })
    }

    upgradeChangeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleNoteSubmit = () => {
        const notesData = { sale_id_fk: this.state.sale.id, user_id_fk: getLoggedInUserID(), notes: this.state.notes };
        if (this.state.notes == '') {
            this.setState({ notesError: "Notes can't be empty." })
        } else {
            axios.post(api_url + '/notes/notes/', notesData, { headers: getAuthHeaders() })
                .then(res => {
                    if (res.data.success) {
                        this.setState({
                            open_notes: false,
                            dynamic_title: res.data.message,
                            success_dlg: true
                        });
                    } else {
                        this.setState({
                            notesError: "Some error occured! please try again later."
                        })
                    }
                });
        }
    }

    handleSaleUpgradeSubmit = () => {
        const upgradeData = {
            sale_id_fk: this.state.sale.id,
            user_id_fk: getLoggedInUserID(),
            notes: this.state.upgrade_notes,
            amount: this.state.upgrade_amount
        };

        if (this.state.upgrade_notes == '' || this.state.upgrade_amount == '') {
            this.setState({
                upgrade_error: 'Please fill all fields.'
            });
        } else {
            axios.post(api_url + '/upgrade/', upgradeData, { headers: getAuthHeaders() })
                .then(res => {
                    if (res.data.success) {
                        this.setState({
                            open_sale_upgrade: false,
                            dynamic_title: res.data.message,
                            success_dlg: true
                        });
                    } else {
                        this.setState({
                            upgrade_error: "Some error occured! please try again later."
                        })
                    }
                });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Sales" breadcrumbItem="Edit Sale" />

                        {this.state.success_dlg ? (
                            <SweetAlert success title={this.state.dynamic_title} onConfirm={() => {
                                this.setState({ success_dlg: false });
                                window.location.reload()
                            }}>
                                {this.state.dynamic_description}
                            </SweetAlert>
                        ) : null}

                        <Row>
                            <Col lg="12">
                                <Card>
                                    <CardBody>

                                        <div className="clearfix mb-2">
                                            {this.state.userRole == 1 || this.state.userRole == 3 ? <Link to="/sales" id="" className="btn btn-primary waves-effect waves-light float-right mb-2">Manage Sales</Link> : null}

                                            {this.state.userRole == 1 || this.state.userRole == 3 ? <Link to="/add-sale" id="" className="btn btn-primary waves-effect waves-light float-right mb-2 mr-2">Add New</Link> : null}
                                        </div>

                                        {this.state.error && this.state.error ? <Alert className="col-lg-12" color="danger">{this.state.error}</Alert> : null}
                                        {this.state.success && this.state.success ? <Alert className="col-lg-12" color="success">{this.state.success}</Alert> : null}
                                        {this.state.loader && this.state.loader ? <div className="my-spinner"><div className="spinner-border text-primary m-1" role="status"><span className="sr-only">Loading...</span></div></div> : null}

                                        <AvForm>

                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField type="select" name="tech_id_fk" label="Select technician" className="capitalize-text" value={this.state.sale.tech_id_fk} required>
                                                                <option defaultValue>Select technician</option>
                                                                {
                                                                    this.state.tech_options.map((tech_user, index) =>
                                                                        <option key={index} value={tech_user.tech_id_fk}>{tech_user.name}</option>
                                                                    )
                                                                }
                                                            </AvField>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="sale_by" label="Sale By" value={this.state.sale.sale_by} className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="payment_by" label="Payment By" value={this.state.sale.payment_by} className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="close_by" label="Close By" value={this.state.sale.closing} className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="firstname" label="First Name" value={this.state.sale.first_name} className="form-control" type="text" readOnly={true} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="lastname" label="Last Name" value={this.state.sale.last_name} className="form-control" type="text" readOnly={true} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="email" label="Email" value={this.state.sale.email} className="form-control" type="email" readOnly={true} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="phone" label="Phone" value={this.state.sale.phone} className="form-control" type="text" readOnly={true} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="alt_phone" label="Alt Phone" value={this.state.sale.altphone} className="form-control" type="text" validate={{ number: true }} required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="cx_id" label="CX ID" value={this.state.sale.cxid} className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="gateway" label="Gateway" value={this.state.gateway} className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="bttr" label="BTTR" value={this.state.sale.bttr} className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="6">
                                                            <AvField name="zipcode" label="Zipcode" value={this.state.sale.zip_code} className="form-control" type="text" readOnly={true} />
                                                        </Col>
                                                        <Col lg="6">
                                                            <AvField name="address" label="Address" value={this.state.sale.address} className="form-control" type="text" readOnly={true} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="6">
                                                            <AvField name="plan" label="Service" value={this.state.sale.plan} className="form-control" type="text" readOnly={true} />
                                                        </Col>
                                                        <Col lg="6">
                                                            <AvField name="amount" label="Amount" value={this.state.sale.amount} className="form-control" type="text" readOnly={true} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="6">
                                                            <label>Date</label>
                                                            <input className="form-control" type="date" defaultValue={this.state.sale.sale_date} onChange={this.setSaleDate} id="example-date-input" readOnly={true} />
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup className="mb-2" row>
                                                                <Col lg="12">
                                                                    <AvField type="select" name="kyc_status" label="KYC Status" className="capitalize-text" required>
                                                                        <option defaultValue>Select status</option>
                                                                        <option value="Yes">Yes</option>
                                                                        <option value="No">No</option>
                                                                    </AvField>
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup className="mb-2" row>
                                                                <Col lg="12">
                                                                    <AvField type="select" name="sale_status" value={this.state.sale.sale_status} label="Status" readOnly={true}>
                                                                        <option defaultValue>Select status</option>
                                                                        <option value="Chargeback">Chargeback</option>
                                                                        <option value="Charged and Closed">Charged & Closed</option>
                                                                        <option value="Pending">Pending</option>
                                                                        <option value="Refunded">Refunded</option>
                                                                    </AvField>
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col lg="6">
                                                            <FormGroup className="mb-2" row>
                                                                <Col lg="12">
                                                                    <AvField name="vendor" label="Vendor" value={this.state.sale.vendor} className="form-control" type="text" required />
                                                                </Col>
                                                            </FormGroup>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            {this.state.sale.is_closed ? <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-4" row>
                                                        <Col lg="12">
                                                            <AvField type="select" name="is_closed" value={this.state.sale.is_closed} label="Is Closed?" required>
                                                                <option defaultValue>Select status</option>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </AvField>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row> : null}

                                            {this.state.sale.closed_reason ? <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-4" row>
                                                        <Col lg="12">
                                                            <AvField name="closed_reason" label="Reason for Closed" className="form-control" type="textarea" value={this.state.sale.closed_reason} required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row> : null}

                                            {this.state.sale.pending_reason ? <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-4" row>
                                                        <Col lg="12">
                                                            <AvField name="pending_reason" label="Reason for Pending" className="form-control" type="textarea" value={this.state.sale.pending_reason} required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row> : null}

                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="notes" label="Technician Notes" className="form-control" type="textarea" value={this.state.sale.notes} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="billing_information" label="Billing Information" className="form-control" type="textarea" value={this.state.sale.billing_information} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="10">
                                                    <AvField name="user_id_fk" value={this.state.current_userID} type="hidden" />
                                                    <AvField name="authtoken" value={this.state.token} type="hidden" />
                                                    <Button type="submit" color="primary">Submit</Button>
                                                </Col>
                                            </Row>

                                        </AvForm>

                                        {/* Upgrade sale */}
                                        {this.state.userRole == 2 ? <Row>
                                            <Col lg="12">

                                                <hr />
                                                <Button color="info" onClick={() => this.setState({ open_notes: true })}>Add Notes</Button>&nbsp;&nbsp;
                                                <Button color="success" onClick={() => this.setState({ open_sale_upgrade: true })}>Upgrade Sale</Button>
                                                <hr />

                                            </Col>
                                        </Row> : null}

                                        {/* Update sale notes */}
                                        {this.state.open_notes ? (
                                            <SweetAlert showCancel title="Leave a note!" cancelBtnBsStyle="danger" confirmBtnBsStyle="success" confirmBtnText="Save"
                                                onConfirm={() => this.handleNoteSubmit()} onCancel={() => this.setState({ open_notes: false })}>

                                                {this.state.notesError && this.state.notesError ? <Alert className="col-lg-12" color="danger">{this.state.notesError}</Alert> : null}
                                                <FormGroup className="mb-2" row>
                                                    <Col lg="12">
                                                        <textarea name="notes" value={this.state.notes} onChange={this.noteChangeHandler} className="form-control" placeholder="Add note"></textarea>
                                                    </Col>
                                                </FormGroup>

                                            </SweetAlert>
                                        ) : null}


                                        {/* Upgrade sale amount */}
                                        {this.state.open_sale_upgrade ? (
                                            <SweetAlert showCancel title="Upgrade Sale!" cancelBtnBsStyle="danger" confirmBtnBsStyle="success" confirmBtnText="Save"
                                                onConfirm={() => this.handleSaleUpgradeSubmit()} onCancel={() => this.setState({ open_sale_upgrade: false })}>

                                                {this.state.upgrade_error && this.state.upgrade_error ? <Alert className="col-lg-12" color="danger">{this.state.upgrade_error}</Alert> : null}
                                                <FormGroup className="mb-2" row>
                                                    <Col lg="12">
                                                        <input name="upgrade_amount" placeholder="Amount" value={this.state.upgrade_amount} onChange={this.upgradeChangeHandler} className="form-control" type="text" />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup className="mb-2" row>
                                                    <Col lg="12">
                                                        <textarea name="upgrade_notes" value={this.state.upgrade_notes} onChange={this.upgradeChangeHandler} className="form-control" placeholder="Add note"></textarea>
                                                    </Col>
                                                </FormGroup>

                                            </SweetAlert>
                                        ) : null}



                                    </CardBody>
                                </Card>
                            </Col>

                            {/* Sale notes */}
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">
                                            Sale Notes
                                        </CardTitle>
                                        <div className="table-responsive first-td-capital">

                                            <MDBDataTable responsive striped bordered data={this.state.sales_notes_list} />

                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            {/* Sale upgrades */}
                            <Col lg="12">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">
                                            Sale Upgrades
                                        </CardTitle>
                                        <div className="table-responsive first-td-capital">

                                            <MDBDataTable responsive striped bordered data={this.state.sales_upgrades_list} />

                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default EditSale;