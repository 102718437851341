import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import axios from 'axios';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getLoggedInUserID, getLoggedInUser, api_url, getAuthHeaders } from '../../helpers/fakebackend_helper';

class ViewUser extends Component {
    constructor() {
        super();
        this.state = {
            current_userID: 0,
            token: '',
            success: '',
            error: '',
            userID: 0,
            user: '',
            loader: false
        }
    }

    componentDidMount() {
        const userID = this.props.match.params.userId;
        const headers = getAuthHeaders();

        this.setState({
            current_userID: getLoggedInUserID(),
            token: getLoggedInUser().data.authtoken,
            userID: userID
        });

        axios.get(api_url + '/user/singleUser/' + userID, { headers: headers })
            .then(res => {
                console.log(res);
                this.setState({
                    user: res.data.data
                });
            });
    }

    renderRow = (heading, text) => {
        return (
            <div>
                <p><strong>{heading}: </strong> {text}</p>
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="User" breadcrumbItem="User Details" />
                        <Row>
                            <Col lg="12">
                                {this.state.user ? <Card>
                                    <CardBody>
                                        <Link to="/users" id="" className="btn btn-primary waves-effect waves-light float-right mb-2">Manage Users</Link>
                                        <Link to="/add-user" id="" className="btn btn-primary waves-effect waves-light float-right mr-2">Add New</Link>
                                        <CardTitle className="mb-4">Personal Information</CardTitle>
                                        <Row>
                                            <Col lg="6">
                                                {this.renderRow('User Type', this.state.user.uacc_group_fk == 1 ? 'Admin' : this.state.user.uacc_group_fk == 2 ? 'Customer Service' : this.state.user.uacc_group_fk == 3 ? 'Sale Agent' : this.state.user.uacc_group_fk == 4 ? 'Technician' : '')}
                                                {this.renderRow('First Name', this.state.user.upro_first_name)}
                                                {this.renderRow('Last Name', this.state.user.upro_last_name)}
                                                {this.renderRow('Username', this.state.user.uacc_username)}
                                                {this.renderRow('Active', this.state.user.uacc_active == 1 ? 'Yes' : 'No')}
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card> : null}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default ViewUser;