import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardTitle, FormGroup, Button, Alert } from "reactstrap";

import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getLoggedInUserID, getLoggedInUser, api_url, getAuthHeaders } from '../../helpers/fakebackend_helper';
import axios from 'axios';


class EditUser extends Component {
    constructor() {
        super();
        this.state = {
            current_userID: 0,
            token: '',
            success: '',
            error: '',
            userID: 0,
            user: '',
            loader: false
        }
    }

    componentDidMount() {
        const userID = this.props.match.params.userId;
        const headers = getAuthHeaders();

        this.setState({
            current_userID: getLoggedInUserID(),
            token: getLoggedInUser().data.authtoken,
            userID: userID
        });

        axios.get(api_url + '/user/singleUser/' + userID, { headers: headers })
            .then(res => {
                console.log(res);
                this.setState({
                    user: res.data.data
                });
            });
    }

    // handleValidSubmit
    handleValidSubmit = (event, values) => {
        this.setState({
            loader: true
        });
        const headers = getAuthHeaders();

        axios.put(api_url + '/user/' + this.state.userID, values, { headers: headers })
            .then(res => {
                if (res.data.success) {
                    this.setState({ success: res.data.message });
                    this.setState({
                        loader: false
                    });
                    setTimeout(() => {
                        this.setState({
                            success: '',
                            error: '',
                            loader: false
                        });
                    }, 4000);
                } else {
                    this.setState({ error: res.data.errors });
                }
            });
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="User" breadcrumbItem="Edit User" />

                        <Row>
                            <Col lg="12">

                                {this.state.user ? <Card>
                                    <CardBody>
                                        <Link to="/users" id="" className="btn btn-primary waves-effect waves-light float-right mb-2">Manage Users</Link>
                                        <Link to="/add-user" id="" className="btn btn-primary waves-effect waves-light float-right mr-2">Add New</Link>
                                        <CardTitle className="mb-4">Personal Information</CardTitle>
                                        <AvForm ref={this.formRef} onValidSubmit={this.handleValidSubmit}>

                                            {this.state.error && this.state.error ? <Alert className="col-lg-12" color="danger">{this.state.error}</Alert> : null}
                                            {this.state.success && this.state.success ? <Alert className="col-lg-12" color="success">{this.state.success}</Alert> : null}

                                            {this.state.loader && this.state.loader ? <div className="my-spinner"><div className="spinner-border text-primary m-1" role="status"><span className="sr-only">Loading...</span></div></div> : null}


                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField type="select" name="uacc_group_fk" label="User Type" value={this.state.user.uacc_group_fk} required>
                                                                <option defaultValue>Select type</option>
                                                                <option value="1">Admin</option>
                                                                <option value="2">Customer Service</option>
                                                                <option value="3">Sale Agent</option>
                                                                <option value="4">Technician</option>
                                                            </AvField>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="upro_first_name" label="First Name" value={this.state.user.upro_first_name} className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="upro_last_name" label="Last Name" value={this.state.user.upro_last_name} className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <hr />
                                            <CardTitle className="mb-4 mt-2">Login Information</CardTitle>

                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="uacc_username" label="Username" value={this.state.user.uacc_username} className="form-control" type="text" required />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField type="select" name="uacc_active" value={this.state.user.uacc_active} label="Active" required>
                                                                <option value="1">Yes</option>
                                                                <option value="0">No</option>
                                                            </AvField>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="uacc_password" label="Password" value="" className="form-control" type="password" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup className="mb-2" row>
                                                        <Col lg="12">
                                                            <AvField name="confirm_password" label="Confirm Password" value="" className="form-control" type="password" validate={{ match: { value: 'uacc_password' } }} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="10">
                                                    <AvField name="id" value={this.state.user.uacc_id} type="hidden" />
                                                    <Button type="submit" color="primary">Submit</Button>
                                                </Col>
                                            </Row>

                                        </AvForm>

                                    </CardBody>
                                </Card> : null}


                            </Col>
                        </Row>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default EditUser;